import React from 'react';
import useDarkMode from 'use-dark-mode';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
  customeBtn: {
    'margin-left': 'auto'
  }
})

const DarkModeToggleBtn = () => {
  const darkMode = useDarkMode(true);
  const classes = useStyle()

  return (
      <Switch
        onChange={darkMode.toggle}
        checked={darkMode.value}
        color="success"
        defaultChecked
        className={classes.customeBtn}
      />
  );
};

export default DarkModeToggleBtn;
